import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import logErrorWithContext from '@hmhco/client-monitoring/src/context/logErrorWithContext';
import useGetCachedOrgInfo from './getCachedOrgInfo.hook';
import useGetConnectionParam from './getConnectionParam.hook';
import { setCachedOrgInfo } from '../store/actions/CountryStateOrgActions';
import getOrgInfoClient from '../api/getOrgInfo';
import useSetUrlParamsInStorage from './setUrlParamsInStorage';
import { getStateFromStateCode } from '../constants/usStates';
import useOktaVisible from './useOktaVisible.hook';
import {
  oktaVisible,
  setCachedOktaWidgetConfig,
} from '../store/actions/TenantPickerActions';
import useOktaParams from './useOktaParams.hook';
import useCachedOktaWidgetConfig from './useCachedOktaWidgetConfig.hook';
import {
  setConnectionPid,
  setOktaConfig,
  setOktaVisible,
} from '../storageHelpers/localStorageAccessors';
import { setCachedOrgInfo as cacheOrgInfo } from '../storageHelpers/sessionStorageAccessors';
import buildSignInUrl from '../api/buildSignInUrl';

function useBootstrapTenantPicker() {
  const dispatch = useDispatch();
  const orgPid = useGetConnectionParam();
  const { allKeysHaveValue, params } = useOktaParams();
  const cachedOrgInfo = useGetCachedOrgInfo(orgPid);
  const cachedOktaWidgetConfig = useCachedOktaWidgetConfig();
  const isOktaVisible = useOktaVisible();
  useSetUrlParamsInStorage();

  useEffect(() => {
    if (cachedOrgInfo) {
      dispatch(setCachedOrgInfo(cachedOrgInfo));
    } else if (orgPid) {
      const { getOrgInfo } = getOrgInfoClient();
      getOrgInfo(orgPid)
        .then(orgInfo => {
          const orgState = getStateFromStateCode(orgInfo.stateCode);
          const country = orgInfo.countryCode
            ? { code: orgInfo.countryCode, name: orgInfo.countryName }
            : { code: 'US', name: 'United States' };
          const newState = {
            data: {
              country,
              state: orgState,
              org: { pid: orgPid, name: orgInfo.name, orgRefId: orgInfo.orgId },
            },
          };
          dispatch(setCachedOrgInfo(newState));

          cacheOrgInfo(orgPid, newState.data);
          setConnectionPid(orgPid);

          buildSignInUrl(orgInfo.orgId).then(signInUrl => {
            setOktaVisible(true);
            dispatch(oktaVisible(true));
            window.location = signInUrl;
          });
        })
        .catch(error => {
          // TODO: Need to ask design what to do in this case. This will likely happen frequently.
          logErrorWithContext('Login app - Error in Organization info', [
            { key: 'errorMessage', value: error },
          ]);
        });
    }

    if (params && allKeysHaveValue) {
      setOktaConfig(JSON.stringify(params));
      dispatch(setCachedOktaWidgetConfig(params));
      window.history.replaceState(
        {},
        document.title,
        window.location.origin + window.location.pathname,
      );
      dispatch(oktaVisible(true));
    }

    if (cachedOktaWidgetConfig) {
      dispatch(setCachedOktaWidgetConfig(JSON.parse(cachedOktaWidgetConfig)));
      dispatch(oktaVisible(true));
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [orgPid, cachedOrgInfo, isOktaVisible]);
}

export default useBootstrapTenantPicker;
