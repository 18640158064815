import React from 'react';
import PropTypes, { oneOf } from 'prop-types';
import TenantPickerForm from './TenantPickerForm';
import { QUERY_PARAMS } from '../../constants/constants';
import useHnmSSORedirect from '../../hooks/useHnmSSORedirect.hook';
import TenantPickerLayout from '../Layout/TenantPickerLayout';

const TenantPickerPage = ({ redirectedFrom, currentTheme }) => {
  if (redirectedFrom) {
    document.title = `Success - ${document.title}`;
  }

  useHnmSSORedirect(currentTheme);

  return (
    <TenantPickerLayout currentTheme={currentTheme}>
      <TenantPickerForm
        redirectedFrom={redirectedFrom}
        currentTheme={currentTheme}
      />
    </TenantPickerLayout>
  );
};

TenantPickerPage.defaultProps = {
  redirectedFrom: '',
  currentTheme: 'HMH',
};

TenantPickerPage.propTypes = {
  redirectedFrom: oneOf([
    QUERY_PARAMS.PASSWORD_RESET,
    QUERY_PARAMS.REGISTER_USER,
  ]),
  currentTheme: PropTypes.string,
};

export default TenantPickerPage;
