import { HcpAlert } from '@hmhco/hcp-alert';
import React, { useEffect, useState } from 'react';
import useGetLoginMessage from '../../../../hooks/getLoginMessage.hook';

const MAXIMUM_NUMBER_OF_LOGIN_MESSAGE_REQUESTS = 10;

/**
 * Should be used to display system warning messages from system (eg. server issues, etc...).
 */
const SystemAlert = () => {
  const [show, setShow] = useState(true);

  const loginMessage = useGetLoginMessage(
    MAXIMUM_NUMBER_OF_LOGIN_MESSAGE_REQUESTS,
  );

  useEffect(() => {
    setShow(true);
  }, [loginMessage?.activate, loginMessage?.title, loginMessage?.content]);

  if (!loginMessage?.activate || !show) {
    return null;
  }

  return (
    <HcpAlert
      size="lg"
      severity="info"
      title={loginMessage.title}
      subtitle={loginMessage.content}
      onClose={() => setShow(false)}
    />
  );
};

export default SystemAlert;
