import { HcpAlert } from '@hmhco/hcp-alert/src/index';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

/**
 * Should be used to display generic error messages from user actions.
 *
 * NOTE: Accessibility. If possible to segragate error contexts then this Alert SHOULD BE used for generic errors ONLY.
 * Any specific contenxt related to field input (username, password, country selected) should be
 * address by corresponding field's error state.
 * @param {Object} message generic message object to show an alert for
 */
const UserAlert = ({ message }) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    setShow(true);
  }, [message?.title, message?.subtitle]);

  if (!message || !show) {
    return null;
  }

  return (
    <HcpAlert
      size="lg"
      severity="info"
      title={message.title}
      subtitle={message.subtitle}
      onClose={() => setShow(false)}
    />
  );
};

UserAlert.propTypes = {
  message: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
  }),
};

UserAlert.defaultProps = {
  message: undefined,
};

export default UserAlert;
