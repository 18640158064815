import { ENVIRONMENTS, PLATFORMS } from '@hmhco/platform-helper';
import { getCurrentEnv } from '@hmhco/amp-core-env';
import {
  getAmpLoginUrl,
  getEdsLoginUrl,
  getFlightLoginUrl,
  getSignedInUrl,
} from '../api/buildAuthenticationUrlHnm';

// eslint-disable-next-line import/prefer-default-export
export const QUERY_PARAMS = {
  FROM: 'from',
  CONNECTION: 'connection',
  PASSWORD_RESET: 'passwordReset',
  REGISTER_USER: 'registerUser',
  TOKEN: 'token',
};
export const HTTP_STATUS_CODES = {
  SUCCESS: 200,
  UNAUTHORIZED: 401,
  CONFLICT: 409,
  SERVICE_UNAVAILABLE: 503,
};

export const AUTO_COMPLETE = {
  GIVEN_NAME: 'given-name',
  FAMILY_NAME: 'family-name',
  USERNAME: 'username',
  PASSWORD: 'current-password',
  NEW_PASSWORD: 'new-password',
  ONE_TIME_CODE: 'one-time-code',
};

export const HNM_SSO_CLIENT_ID = '2cc7842e-ed1b-401e-b203-c2756a26d795';
export const HMH_SSO_CLIENT_ID = '8615f365-7cad-4dc7-a9bb-18f3cd99fb25';
export const HMH_NON_SSO_CLIENT_ID = '80B4EFE0-B723-46AC-A46A-C75037B44F86';
export const NON_SSO_ISSUER = 'https://aqueductpreview.hmhco.com';
export const LOCAL_CLIENT_ID = '219cadbb-0827-4f1c-8e40-8129f778c4b3';

const getLoginUri = platform => {
  return platform === PLATFORMS.ED ? getAmpLoginUrl() : getFlightLoginUrl();
};

/**
 * Retrieves the Single Sign-On (SSO) configuration for a given platform.
 * @param {'HNM' | 'HMH'} platform The platform for which to retrieve the SSO configuration.
 * @returns {Object} The full configuration object for the eds-login Authorize class.
 */
export const getSSOConfiguration = platform => {
  const CLIENT_ID =
    platform === PLATFORMS.ED ? HMH_SSO_CLIENT_ID : HNM_SSO_CLIENT_ID;
  return {
    loginUri: getLoginUri(platform),
    clientId: CLIENT_ID,
    targetLinkUri: getSignedInUrl(),
    baseUri: getEdsLoginUrl(),
  };
};

/**
 * Retrieves the NON SSO configuration for a given platform.
 * @param {'HNM' | 'HMH'} platform The platform for which to retrieve the SSO configuration.
 * @returns {Object} The full configuration object for the eds-login getThirdPartyLoginUri() call used in the Authorize class.
 */
export const getNonSSOConfiguration = platform => {
  const CLIENT_ID =
    platform === PLATFORMS.ED ? HMH_NON_SSO_CLIENT_ID : HNM_SSO_CLIENT_ID;
  return {
    loginUri: getLoginUri(platform),
    clientId: CLIENT_ID,
    targetLinkUri: getSignedInUrl(),
    baseUri: getEdsLoginUrl(),
  };
};

/* This mocking will be replaced by an API for initializeURI */
export const INITIALIZE_URI_MOCKING = {
  ssoAccount: false,
  oktaEmbeddedWidget: true,
};

export const getClientId = () => {
  const currentEnv = getCurrentEnv();
  if (currentEnv === ENVIRONMENTS.local) return LOCAL_CLIENT_ID;

  return HMH_SSO_CLIENT_ID;
};
