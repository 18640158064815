import baseTheme from './baseTheme';
import createBaseThemeSettings from './createBaseThemeSettings';
import getHexFromVariable from './getHexFromVariable';
import createPalette from './createPalette';

export {
  baseTheme,
  createBaseThemeSettings,
  getHexFromVariable,
  createPalette,
};
export default baseTheme;
