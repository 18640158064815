import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import InfoRequiredFields from '@hmhco/form-components/src/InfoRequiredFields';
import useStyles from './TenantPickerForm.styles';
import LoginLogo from '../Layout/LoginLogo';
import LoginFooter from '../Layout/LoginFooter';
import TenantPickerPageButtons from './TenantPickerPageButtons';
import useBootstrapTenantPicker from '../../hooks/useBootstrapTenantPicker.hook';
import useCheckLoginCookies from '../../hooks/checkLoginCookies.hook';
import HCPUsernameAndPasswordForm from '../UsernameAndPassword/HCPUsernameAndPasswordForm';
import HCPCountryStateAndOrgForm from '../CountryStateAndOrg/HCPCountryStateAndOrgForm';
import OktaForm from '../OktaSignInWidget/OktaForm';
import Alerts from './Alerts/Alerts';

const TenantPickerForm = ({ currentTheme }) => {
  const { classes } = useStyles();
  useBootstrapTenantPicker();
  useCheckLoginCookies();

  return (
    <Grid
      container
      direction="column"
      component="form"
      className={classes.loginForm}
    >
      <LoginLogo currentTheme={currentTheme} />
      <Alerts />
      <Typography component="h1" variant="h3" className={classes.title}>
        <FormattedMessage id="tenantPicker.pageTitle" />
      </Typography>
      <InfoRequiredFields fontSize="12" />
      <HCPCountryStateAndOrgForm />
      <OktaForm />
      <HCPUsernameAndPasswordForm />
      <TenantPickerPageButtons currentTheme={currentTheme} />
      <LoginFooter currentTheme={currentTheme} />
    </Grid>
  );
};

TenantPickerForm.defaultProps = {
  currentTheme: 'HMH',
};

TenantPickerForm.propTypes = {
  currentTheme: PropTypes.string,
};

export default TenantPickerForm;
