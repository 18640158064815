import { Authorize } from '@hmh/eds-login-js';
import {
  createForm,
  setAttributes,
  setPassword,
  setUsername,
  submitForm,
} from '../api/formHelpers';
import { handleSSOError as handleEDSError } from '../components/SSOFlow/SSOPage';
import { NON_SSO_ISSUER, getNonSSOConfiguration } from '../constants/constants';

/**
 * @name handleSignIn utility
 * @summary Uses EDS login to authenticate, and then uses the signed-in app to boostrap the user into session storage and sign in
 * @description
 * Login is using a form submission because "Architecture" want login to do a browser redirect rather
 * respond with a JSON payload to reduce the likelyhood of man in the middle. This means that the UI
 * code ends up being convoluted and difficult to PACT test as the JSDOM environment does not support
 * native form submissions. This also greatly increases the risk of MI's as the PACT tests are not using
 * the same API call that is made by real users. Our PACT workaround is to use the formHelpers below to
 * monitor what is being set on the form and then replicating this same data in an axios call that's made in the PACT test.
 */
const handleSignIn = ({ platform, username, password, orgPid }) => {
  const { clientId, baseUri, loginUri, targetLinkUri } = getNonSSOConfiguration(
    platform,
  );

  const authorize = new Authorize(clientId, NON_SSO_ISSUER, baseUri, {
    connection: orgPid,
    password,
    targetLinkUri,
    username,
  });

  authorize
    .getThirdPartyLoginUri()
    .then(loginInitUri => {
      const form = createForm();
      const formAction = `${loginInitUri}&connection=${orgPid}`;
      setAttributes(form, {
        targetLinkUri,
        method: 'post',
        url: formAction,
        encoding: 'application/x-www-form-urlencoded',
      });
      setUsername(form, username);
      setPassword(form, password);
      submitForm(form);
    })
    .catch(error =>
      handleEDSError({
        platform,
        error,
        loginUri,
      }),
    );
};

export default handleSignIn;
