import React from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { object, string } from 'prop-types';
import TranslationMessageProvider from '@hmhco/i18n-react/src/TranslationsMessageProvider';
import ctsDefaultTheme from '@hmhco/cts-default-theme';
import TopLevelThemeProvider from '@hmhco/toplevel-theme-provider';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import ErrorBoundary from './components/ErrorBoundaryComponent';
import { defaultTranslations, getLocaleFile } from './lang';
import LoginPage from './components/LoginPage/LoginPage';
import TenantPickerPage from './components/TenantPicker/TenantPickerPage';
import ForgotPasswordPage from './components/ForgotPasswordPage/ForgotPasswordPage';
import RequestSignInDetailsPage from './components/RequestSignInDetailsPage/RequestSignInDetailsPage';
import rootReducer from './store/reducers';
import NewPasswordPage from './components/NewPasswordPage/NewPasswordPage';
import { QUERY_PARAMS } from './constants/constants';
import RegisterUser from './components/RegisterUser/RegisterUser';
import {
  currentRouteIntegration,
  environment,
} from '../../../../environments/environment';
import SSOFlow from './components/SSOFlow/SSOPage';

export const store = createStore(rootReducer);

const App = ({ languageKey, theme }) => {
  const configuration =
    environment.allConfigurations[currentRouteIntegration()].name;
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <BrowserRouter basename="/ui/login/">
          <TranslationMessageProvider
            locale={languageKey}
            messageFetchFunction={getLocaleFile}
            defaultTranslations={defaultTranslations}
          >
            {messages => (
              <IntlProvider locale={languageKey} messages={messages}>
                <TopLevelThemeProvider theme={theme}>
                  <Switch>
                    <Route path="/sso">
                      <SSOFlow platform={configuration} />
                    </Route>
                    <Route path="/forgot-password/">
                      <ForgotPasswordPage currentTheme={configuration} />
                    </Route>
                    <Route path="/request-signin-details/">
                      <RequestSignInDetailsPage currentTheme={configuration} />
                    </Route>
                    <Route path="/new-password/">
                      <NewPasswordPage currentTheme={configuration} />
                    </Route>
                    <Route path="/register">
                      <RegisterUser currentTheme={configuration} />
                    </Route>
                    <Route
                      path="/tenant-picker"
                      render={match => {
                        const redirectedFrom = new URLSearchParams(
                          match.location.search,
                        ).get(QUERY_PARAMS.FROM);

                        return (
                          <TenantPickerPage
                            redirectedFrom={redirectedFrom}
                            currentTheme={configuration}
                          />
                        );
                      }}
                    />
                    <Route
                      path="/"
                      render={match => {
                        const redirectedFrom = new URLSearchParams(
                          match.location.search,
                        ).get(QUERY_PARAMS.FROM);

                        return (
                          <LoginPage
                            redirectedFrom={redirectedFrom}
                            currentTheme={configuration}
                          />
                        );
                      }}
                    />
                  </Switch>
                </TopLevelThemeProvider>
              </IntlProvider>
            )}
          </TranslationMessageProvider>
        </BrowserRouter>
      </Provider>
    </ErrorBoundary>
  );
};

App.propTypes = {
  languageKey: string,
  theme: object,
};

App.defaultProps = {
  languageKey: 'en-US',
  theme: ctsDefaultTheme,
};

export default App;
