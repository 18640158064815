import { getOktaConfig } from '../storageHelpers/localStorageAccessors';

function useCachedOktaWidgetConfig() {
  const cachedConfig = getOktaConfig();
  if (!cachedConfig) {
    return null;
  }
  return cachedConfig;
}

export default useCachedOktaWidgetConfig;
