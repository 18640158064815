export default {
  fontFamily: 'var(--ebl-wf-headings)',
  // headings
  h1: {
    fontFamily: 'var(--ebl-wf-headings)',
    fontSize: 'var(--ebl-h1)',
    lineHeight: 'var(--ebl-h1-line-height)',
    letterSpacing: 'var(--ebl-h1-letter-spacing)',
    color: 'var(--ebl-h1-color)',
    fontWeight: 'var(--ebl-h1-weight)',
  },
  h2: {
    fontFamily: 'var(--ebl-wf-headings)',
    fontSize: 'var(--ebl-h2)',
    lineHeight: 'var(--ebl-h2-line-height)',
    letterSpacing: 'var(--ebl-h2-letter-spacing)',
    color: 'var(--ebl-h2-color)',
    fontWeight: 'var(--ebl-h2-weight)',
  },
  h3: {
    fontFamily: 'var(--ebl-wf-headings)',
    fontSize: 'var(--ebl-h3)',
    lineHeight: 'var(--ebl-h3-line-height)',
    letterSpacing: 'var(--ebl-h3-letter-spacing)',
    color: 'var(--ebl-h3-color)',
    fontWeight: 'var(--ebl-h3-weight)',
  },
  h4: {
    fontFamily: 'var(--ebl-wf-headings)',
    fontSize: 'var(--ebl-h4)',
    lineHeight: 'var(--ebl-h4-line-height)',
    letterSpacing: 'var(--ebl-h4-letter-spacing)',
    color: 'var(--ebl-h4-color)',
    fontWeight: 'var(--ebl-h4-weight)',
  },
  h5: {
    fontFamily: 'var(--ebl-wf-headings)',
    fontSize: 'var(--ebl-h5)',
    lineHeight: 'var(--ebl-h5-line-height)',
    letterSpacing: 'var(--ebl-h5-letter-spacing)',
    color: 'var(--ebl-h5-color)',
    fontWeight: 'var(--ebl-h5-weight)',
  },
  h6: {
    fontFamily: 'var(--ebl-wf-headings)',
    fontSize: 'var(--ebl-h6)',
    lineHeight: 'var(--ebl-h6-line-height)',
    letterSpacing: 'var(--ebl-h6-letter-spacing)',
    color: 'var(--ebl-h6-color)',
    fontWeight: 'var(--ebl-h6-weight)',
  },
  /* --Subheadings-- */
  subtitle1: {
    fontFamily: 'var(--ebl-wf-headings)',
    fontSize: 'var(--ebl-sh1)',
    lineHeight: 'var(--ebl-sh1-line-height)',
    letterSpacing: 'var(--ebl-sh1-letter-spacing)',
    color: 'var(--ebl-sh1-color)',
    fontWeight: 'var(--ebl-sh1-weight)',
  },
  subtitle2: {
    fontFamily: 'var(--ebl-wf-headings)',
    fontSize: 'var(--ebl-sh2)',
    lineHeight: 'var(--ebl-sh2-line-height)',
    letterSpacing: 'var(--ebl-sh2-letter-spacing)',
    color: 'var(--ebl-sh2-color)',
    fontWeight: 'var(--ebl-sh2-weight)',
  },
  overline: {
    fontFamily: 'var(--ebl-wf-headings)',
    fontSize: 'var(--ebl-sh3)',
    lineHeight: 'var(--ebl-sh3-line-height)',
    letterSpacing: 'var(--ebl-sh3-letter-spacing)',
    color: 'var(--ebl-sh3-color)',
    fontWeight: 'var(--ebl-sh3-weight)',
    textTransform: 'none',
  },
  button: {
    fontFamily: 'var(--ebl-wf-headings)',
    fontSize: 'var(--ebl-sh4)',
    lineHeight: 'var(--ebl-sh4-line-height)',
    letterSpacing: 'var(--ebl-sh4-letter-spacing)',
    color: 'var(--ebl-sh4-color)',
    fontWeight: 'var(--ebl-sh4-weight)',
    textTransform: 'none',
  },
  /* --Body-- */
  body1: {
    fontFamily: 'var(--ebl-wf-body)',
    fontSize: 'var(--ebl-p1)',
    lineHeight: 'var(--ebl-p1-line-height)',
    letterSpacing: 'var(--ebl-p1-letter-spacing)',
    color: 'var(--ebl-p1-color)',
    fontWeight: 'var(--ebl-noto)',
  },
  body2: {
    fontFamily: 'var(--ebl-wf-body)',
    fontSize: 'var(--ebl-p2)',
    lineHeight: 'var(--ebl-p2-line-height)',
    letterSpacing: 'var(--ebl-p2-letter-spacing)',
    color: 'var(--ebl-p2-color)',
    fontWeight: 'var(--ebl-noto)',
  },
  // p1 bold
  body1Bold: {
    fontFamily: 'var(--ebl-wf-body)',
    fontSize: 'var(--ebl-p1)',
    lineHeight: 'var(--ebl-p1-line-height)',
    letterSpacing: 'var(--ebl-p1-letter-spacing)',
    color: 'var(--ebl-p1-color)',
    // fontWeight: 'new var', once baseline variable is available need to replace it with variable
    fontWeight: '700',
  },

  // p2 bold
  body2Bold: {
    fontFamily: 'var(--ebl-wf-body)',
    fontSize: 'var(--ebl-p2)',
    lineHeight: 'var(--ebl-p2-line-height)',
    letterSpacing: 'var(--ebl-p2-letter-spacing)',
    color: 'var(--ebl-p2-color)',
    // fontWeight: 'new var', once baseline variable is available need to replace it with variable
    fontWeight: '700',
  },
};
