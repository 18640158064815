import React from 'react';
import UserAlert from './UserAlert/UserAlert';
import OktaAlert from './OktaAlert/OktaAlert';
import SuccessAlert from './SuccessAlert/SuccessAlert';
import SystemAlert from './SystemAlert/SystemAlert';
import useStyles from './Alerts.styles';

const Alerts = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <OktaAlert />
      <UserAlert />
      <SystemAlert />
      <SuccessAlert />
    </div>
  );
};

export default Alerts;
