import {
  getConfigForCurrentEnv,
  getConfigTypeForPlatform,
} from '@hmhco/amp-core-env';
import logErrorWithContext from '@hmhco/client-monitoring/src/context/logErrorWithContext';
import requestHelper from '@hmhco/core-network/src/utils/requestHelper';
import { createAxiosCancelable } from '@hmhco/core-network/src/axiosHelpers';
import { getPlatform } from '@hmhco/platform-helper';
import { getClientId, NON_SSO_ISSUER } from '../constants/constants';

async function buildSignInUrl(orgRefId) {
  const { edsInitUrl, ampSignedInURL } = getConfigForCurrentEnv(
    getConfigTypeForPlatform(getPlatform()),
  );
  if (!orgRefId) {
    throw new Error('buildSignInUrl requires an orgRefId');
  }
  const params = new URLSearchParams();
  params.append('client_id', getClientId());
  params.append('target_link_uri', ampSignedInURL);
  params.append('iss', NON_SSO_ISSUER);
  params.append('login_hint', orgRefId);

  const { client, cancelToken, isCancel } = createAxiosCancelable({
    includeAuth: false,
  });
  const request = requestHelper({
    client,
    cancelToken,
    isCancel,
    baseURL: edsInitUrl,
    pactConfig: {},
  });
  try {
    const response = await request.get(`?${params.toString()}`);
    const { login_init_uri: loginInitUri } = response;
    return loginInitUri;
  } catch (error) {
    logErrorWithContext(`Login form - Error building sign in URL`, [error]);
    throw new Error(error);
  }
}

export default buildSignInUrl;
