import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'LoginMarketingSection' })({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 'var(--ebl-s2)',
    paddingBottom: 'var(--ebl-s4)',
  },
});
