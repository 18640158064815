import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'LoginFooter' })(theme => ({
  loginFooterContainer: {
    marginTop: 'auto',
    paddingTop: 'var(--ebl-s3)',
    paddingBottom: 'var(--ebl-s3)',
    textAlign: 'center',
  },
  termsAndConditions: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: 'var(--ebl-s3)',
    },
  },
  privacyPolicy: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: 'var(--ebl-s3)',
    },
  },
}));
