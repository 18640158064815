import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import logErrorWithContext from '@hmhco/client-monitoring/src/context/logErrorWithContext';
import useGetCachedOrgInfo from './getCachedOrgInfo.hook';
import useGetConnectionParam from './getConnectionParam.hook';
import { setCachedOrgInfo } from '../store/actions/CountryStateOrgActions';
import getOrgInfoClient from '../api/getOrgInfo';
import useSetUrlParamsInStorage from './setUrlParamsInStorage';
import { getStateFromStateCode } from '../constants/usStates';

function useBootstrapOrgInfo() {
  const dispatch = useDispatch();
  const orgPid = useGetConnectionParam();
  const cachedOrgInfo = useGetCachedOrgInfo(orgPid);
  useSetUrlParamsInStorage();
  useEffect(() => {
    if (cachedOrgInfo) {
      dispatch(setCachedOrgInfo(cachedOrgInfo));
    } else if (orgPid) {
      const { getOrgInfo } = getOrgInfoClient();
      getOrgInfo(orgPid)
        .then(orgInfo => {
          const state = getStateFromStateCode(orgInfo.stateCode);
          const country = orgInfo.countryCode
            ? { code: orgInfo.countryCode, name: orgInfo.countryName }
            : { code: 'US', name: 'United States' };
          const newState = {
            data: {
              country,
              state,
              org: { pid: orgPid, name: orgInfo.name },
            },
          };
          dispatch(setCachedOrgInfo(newState));
        })
        .catch(error => {
          // TODO: Need to ask design what to do in this case. This will likely happen frequently.
          logErrorWithContext('Login app - Error in Organization info', [
            { key: 'errorMessage', value: error },
          ]);
        });
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [orgPid, cachedOrgInfo]);
}

export default useBootstrapOrgInfo;
