import { createSelector } from 'reselect';

export const getTenantPickerState = state => state.tenantPickerState;

export const getOktaVisible = createSelector(
  [getTenantPickerState],
  tenantPickerState => Boolean(tenantPickerState.oktaVisible),
);

export const getCachedOktaWidgetConfig = createSelector(
  [getTenantPickerState],
  tenantPickerState => tenantPickerState.oktaWidgetConfig,
);
