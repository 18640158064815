export const isOktaAvailable = (isVisible, oktaInfo, widgetRef) =>
  isVisible && !!oktaInfo.issuer && !!widgetRef.current;

export const updateLabels = widgetRef => {
  const labels = widgetRef.current.querySelectorAll('label');

  labels.forEach(label => {
    let asterisk = label.querySelector('span');
    if (asterisk) {
      return;
    }
    asterisk = document.createElement('span');
    asterisk.setAttribute('aria-hidden', 'true');
    asterisk.textContent = '*';
    label.append(asterisk);
  });
};

export const updateForgotPassword = widgetRef => {
  const formElement = widgetRef.current.querySelector('[data-se="o-form"]');

  if (!formElement) {
    return;
  }

  const doesForgetPasswordContainerExist = !!formElement.querySelector(
    '#forget-password-container',
  );

  if (!doesForgetPasswordContainerExist) {
    const forgetPasswordElement = widgetRef.current.querySelector(
      '[data-se="forgot-password"]',
    );
    if (forgetPasswordElement) {
      const forgetPasswordContainer = document.createElement('div');
      forgetPasswordContainer.id = 'forget-password-container';
      const paragraphElement = document.createElement('p');
      paragraphElement.id = 'forget-password-paragraph';
      paragraphElement.appendChild(forgetPasswordElement);
      forgetPasswordContainer.appendChild(paragraphElement);
      const buttonsContainer = formElement.querySelector('.o-form-button-bar');
      formElement.insertBefore(forgetPasswordContainer, buttonsContainer);
    }
  }
};

export const destroyFieldObservers = fieldObservers => {
  fieldObservers.forEach(fieldObserver => fieldObserver.disconnect());
};

export const updateFields = widgetRef => {
  const fieldContainers = widgetRef.current.querySelectorAll(
    '.o-form-fieldset',
  );

  fieldContainers.forEach(fieldContainer => {
    let fieldsetElement = fieldContainer.querySelector('fieldset');

    if (!fieldsetElement) {
      fieldsetElement = document.createElement('fieldset');
      fieldsetElement.setAttribute('aria-hidden', 'true');
      const controlContainerElement = fieldContainer.querySelector(
        '.o-form-control',
      );
      controlContainerElement?.append(fieldsetElement);
    }

    const labelElement = fieldContainer.querySelector('label');

    const hasError = !!fieldContainer.querySelector('.o-form-input-error');

    if (hasError) {
      fieldsetElement?.classList?.add('error');
      labelElement?.classList?.add('error');
    } else {
      fieldsetElement?.classList?.remove('error');
      labelElement?.classList?.remove('error');
    }
  });
};

export const updateBackButton = widgetRef => {
  const backButton = widgetRef.current.querySelector('[data-se="cancel"]');
  const buttonsContainer = widgetRef.current.querySelector(
    '.o-form-button-bar',
  );
  if (!backButton) {
    buttonsContainer.style = 'justify-content: end';
    return;
  }

  const nextButton = buttonsContainer.querySelector('[value="Next"]');
  if (nextButton) {
    nextButton.value = 'Submit';
  }

  backButton.textContent = 'Back to Sign In';
  buttonsContainer.style = 'justify-content: space-between';
  buttonsContainer.prepend(backButton);
};

export const updateAlerts = widgetRef => {
  const oktaCallout = widgetRef.current.querySelector('[data-se="callout"]');
  const alertContainer = widgetRef.current.querySelector('#okta-alert');
  if (!alertContainer) {
    return;
  }

  if (oktaCallout) {
    alertContainer.querySelector('p').textContent =
      oktaCallout.children[1].textContent;
    alertContainer.hidden = false;
  } else {
    alertContainer.querySelector('p').textContent = '';
    alertContainer.hidden = true;
  }
};
