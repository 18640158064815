import { destroyFieldObservers } from './utils';
import { updateWidget } from './updateWidget';
import { createFieldObservers } from './createFieldObservers';

export const createObserversToUpdateWidget = widgetRef => {
  const observers = {
    fieldObservers: [],
    containerObserver: null,
  };

  const widgetInnerElement = widgetRef.current.firstChild;

  if (!widgetInnerElement) {
    return observers;
  }

  const observer = new MutationObserver((_, widgetObserver) => {
    const authContentElement = widgetRef.current.querySelector(
      '.auth-content-inner',
    );

    if (!authContentElement) {
      return;
    }

    observers.containerObserver = new MutationObserver(() => {
      destroyFieldObservers(observers.fieldObservers);
      updateWidget(widgetRef);
      observers.fieldObservers = createFieldObservers(widgetRef);
    });

    observers.containerObserver.observe(authContentElement, {
      attributes: true,
      childList: true,
      characterData: true,
    });

    widgetObserver.disconnect();
  });

  observer.observe(widgetInnerElement, {
    attributes: true,
    childList: true,
    characterData: true,
  });

  return observers;
};

export default createObserversToUpdateWidget;
