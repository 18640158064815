import { HcpAlert } from '@hmhco/hcp-alert/src/index';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { QUERY_PARAMS } from '../../../../constants/constants';

const SUCCESS_MESSAGE_IDS = {
  [QUERY_PARAMS.PASSWORD_RESET]: {
    titleId: 'login.form.success.passwordReset.title',
    subtitleId: 'login.form.success.passwordReset.subtitle',
  },
  [QUERY_PARAMS.REGISTER_USER]: {
    titleId: 'login.form.success.newUserCreated.title',
    subtitleId: 'login.form.success.newUserCreated.subtitle',
  },
};

/**
 * Should be used to display success messages from redirects and other related successful actions.
 *
 */
const SuccessAlert = () => {
  const [show, setShow] = useState(true);
  const { formatMessage } = useIntl();
  const redirectedFrom = new URLSearchParams(window.location.search).get(
    QUERY_PARAMS.FROM,
  );

  useEffect(() => {
    setShow(true);
  }, [redirectedFrom]);

  const successMessage =
    !!redirectedFrom && SUCCESS_MESSAGE_IDS[redirectedFrom];

  if (!successMessage || !show) {
    return null;
  }

  return (
    <HcpAlert
      size="lg"
      severity="success"
      title={formatMessage({ id: successMessage.titleId })}
      subtitle={formatMessage({ id: successMessage.subtitleId })}
      onClose={() => setShow(false)}
    />
  );
};

export default SuccessAlert;
