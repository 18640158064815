/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import { FormattedMessage } from 'react-intl';
import Grid from '@mui/material/Grid';
import { PLATFORMS } from '@hmhco/platform-helper';
import useStyles from './TenantPickerPageButtons.styles';
import handleSignIn from '../../util/handleSignIn';
import {
  getCountryStateOrg,
  getCurrentPid,
  getRenderNextButton,
} from '../../store/selectors/CountryStateOrg';
import {
  setCachedOrgInfo,
  setNextButtonPressedFlag,
} from '../../storageHelpers/sessionStorageAccessors';
import {
  setConnectionPid,
  setOktaVisible,
} from '../../storageHelpers/localStorageAccessors';
import {
  getPassword,
  getShowUsernameAndPassword,
  getUsername,
} from '../../store/selectors/UsernameAndPassword';
import { setShowUsernameAndPassword } from '../../store/actions/UsernameAndPasswordActions';
import { INITIALIZE_URI_MOCKING } from '../../constants/constants';
import { oktaVisible } from '../../store/actions/TenantPickerActions';
import { getOktaVisible } from '../../store/selectors/TenantPickerState';
import buildSignInUrl from '../../api/buildSignInUrl';

/**
 * @name TenantPickerPageButtons
 * @param {string} currentTheme | PLATFORMS.ED or PLATFORMS.FLIGHT
 * @summary Checks feature flagging for okta embedded widget, or SSO redirection. Otherwise signs in via eds login.
 * @returns Button
 */
const TenantPickerPageButtons = ({ currentTheme }) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const orgPid = useSelector(getCurrentPid);
  const renderSignInButton = useSelector(getShowUsernameAndPassword);
  const renderNextButton = useSelector(getRenderNextButton);
  const orgData = useSelector(getCountryStateOrg);
  const username = useSelector(getUsername);
  const password = useSelector(getPassword);
  const isOktaVisible = useSelector(getOktaVisible);
  const { ssoAccount, oktaEmbeddedWidget } = INITIALIZE_URI_MOCKING; // This will be the response from the initializeURI endpoint

  if ((!renderSignInButton && !renderNextButton) || isOktaVisible) {
    return null;
  }

  const handleEDSLoginFlow = () => {
    dispatch(setShowUsernameAndPassword(true));
    setConnectionPid(orgPid);
    setCachedOrgInfo(orgPid, orgData);
    setNextButtonPressedFlag();
  };

  const handleNext = async () => {
    if (ssoAccount) {
      /*
        Extract hook from SSOPage.js into reusable hook and provide here
        This will be done in another ticket
        return useSSOLogin(platform)
      */
    }

    if (oktaEmbeddedWidget) {
      setConnectionPid(orgPid);
      setCachedOrgInfo(orgPid, orgData);
      setOktaVisible(true);
      dispatch(oktaVisible(true));

      await buildSignInUrl(orgData.org.orgRefId).then(signInUrl => {
        window.location = signInUrl;
      });
      return;
    }

    /* Otherwise continue to show username and password as expected */
    handleEDSLoginFlow();
  };

  const clickHandler = event => {
    event.preventDefault();
    if (!renderSignInButton) return handleNext();
    if (oktaEmbeddedWidget && !isOktaVisible) return handleNext();

    /*
      At this point we know that we are only signing in via eds login
      no okta embedded widget, and no sso flow needed
    */
    return handleSignIn({
      platform: currentTheme,
      username,
      password,
      orgPid,
    });
  };

  return (
    <Grid item className={classes.submit}>
      <Button
        variant="contained"
        color="primary"
        type="submit"
        onClick={clickHandler}
      >
        <FormattedMessage id="tenantPicker.form.button.continue" />
      </Button>
    </Grid>
  );
};

TenantPickerPageButtons.propTypes = {
  currentTheme: PropTypes.string,
};

TenantPickerPageButtons.defaultProps = {
  currentTheme: PLATFORMS.ED,
};

export default TenantPickerPageButtons;
