import { createAndDisplayOktaWidget } from './createAndDisplayOktaWidget';
import { createObserversToUpdateWidget } from './createObserversToUpdateWidget';
import { isOktaAvailable } from './utils';

export const initializeOktaForm = (isVisible, oktaInfo, widgetRef) => {
  if (!isOktaAvailable(isVisible, oktaInfo, widgetRef)) {
    return () => {};
  }

  const oktaWidget = createAndDisplayOktaWidget(oktaInfo, widgetRef);

  const observers = createObserversToUpdateWidget(widgetRef);

  return () => {
    oktaWidget.remove();
    observers.containerObserver?.disconnect();
    observers.fieldObservers.forEach(fieldObserver =>
      fieldObserver.disconnect(),
    );
  };
};

export default initializeOktaForm;
