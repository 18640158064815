export const LANG = {
  EN: 'en-US',
  ES: 'es-US',
};
export const SEVERITY = {
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
  WARNING: 'warning',
  NEUTRAL: 'neutral',
};
export const ALIGNMENT = {
  INLINE: 'inline',
  STACKED: 'stacked',
  NONE: 'none',
};
export const ALERT_SIZE = {
  LARGE: 'lg',
  SMALL: 'sm',
};

export const ALERT_TEST_ID = {
  ALERT: 'AlertMessageTestId',
  TITLE: 'AlertTitleTestId',
  SUBTITLE: 'AlertSubtitleTestId',
  CLOSE_BUTTON: 'default_close_button',
  CUSTOM_ACTION: 'custom_action',
};

export const STORYBOOK_ACTIONS = {
  BUTTON_CLICK: 'button-click',
};
export const ALERT_TITLE = 'Alert Title';
export const ALERT_SUBTITLE = 'Alert Subtitle, description';
export const ACTION_TYPE = {
  UNDEFINED: undefined,
  NULL: null,
  COMPONENT: 'component',
};
