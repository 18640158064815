import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import Icon from '@hmhco/icon';
import shareSvgSm from '@ed/baseline/icons/cts/change-md.svg';
import { Grid } from '@mui/material';
import { getCurrentOrg } from '../../store/selectors/CountryStateOrg';
import useStyles from './OktaForm.styles';
import {
  getCachedOktaWidgetConfig,
  getOktaVisible,
} from '../../store/selectors/TenantPickerState';
import { oktaVisible } from '../../store/actions/TenantPickerActions';
import useInitializeOktaForm from './useInitializeOktaForm/useInitializeOktaForm';

const OktaForm = () => {
  const { classes } = useStyles();
  const currentOrg = useSelector(getCurrentOrg);
  const dispatch = useDispatch();
  const isVisible = useSelector(getOktaVisible);
  const widgetRef = useRef();
  const oktaInfo = useSelector(getCachedOktaWidgetConfig);

  useInitializeOktaForm(isVisible, oktaInfo, widgetRef);

  const handleChangeClick = () => {
    dispatch(oktaVisible(false));
  };

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <Typography component="h3" variant="h6">
        <FormattedMessage id="tenantPicker.selectedOrg" />
      </Typography>
      <Grid container alignItems="center">
        <Typography component="h3" variant="subtitle1">
          {currentOrg?.name}
        </Typography>
        <Button
          size="small"
          className={classes.changeButton}
          startIcon={
            <Icon
              svg={shareSvgSm}
              size="16"
              colour="var(--ebl-button-frameless-text-color)"
            />
          }
          variant="text"
          fullWidth={false}
          onClick={handleChangeClick}
        >
          <FormattedMessage id="tenantPicker.changeOrg" />
        </Button>
      </Grid>
      <div>
        <div id="signin-widget" ref={widgetRef} className={classes.widget} />
      </div>
    </>
  );
};

export default OktaForm;
