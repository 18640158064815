import { FlightProduct } from '@hnm/types';
import { Auth } from './Auth';
import { Sif } from './Sif';

const AUTH_KEY = 'HCP_AUTH';
const ENTITLEMENTS_KEY = 'HCP_USER_ENTITLEMENTS';

const env = process.env.RUNTIME_ENV || window.RUNTIME_ENV || 'local';
const SIF_KEY = `com.hmhco.security.openID.authInfo.${env}`;

export const hasAuth = (): boolean => {
  return Boolean(sessionStorage.getItem(AUTH_KEY));
};

export const getAuth = (): Auth => {
  const content = sessionStorage.getItem(AUTH_KEY);
  if (content) {
    return JSON.parse(content) as Auth;
  }

  throw new Error('Auth tokens not set.');
};

export function decodeJsonWebToken(jwt) {
  let decodedAccessToken = {};
  const encoded = jwt && jwt.split('.')[1];
  const claim = window.atob(encoded);
  decodedAccessToken = JSON.parse(claim);
  return decodedAccessToken;
}

export function decodeAccessToken(accessToken) {
  if (accessToken) {
    const encodedJwt = accessToken.replace('SIF_HMACSHA256 ', '');
    const jwt = window.atob(encodedJwt).trim();
    return decodeJsonWebToken(jwt);
  }
  return undefined;
}

export function decodeTokens(encodedJsonWebToken) {
  return decodeJsonWebToken(encodedJsonWebToken);
}

export function storeTokenInStorage({ upstreamId, sif, environment }) {
  let successValue = true;
  const token = decodeTokens(upstreamId);
  const accessTokenWithSpaces = sif.replace(/\+/g, ' ');
  const sessionToken = {
    accessToken: decodeURIComponent(accessTokenWithSpaces),
    tokeninfo: token,
    idToken: upstreamId,
  };

  try {
    window.sessionStorage.setItem(
      `com.hmhco.security.openID.authInfo.${environment}`,
      JSON.stringify({ sif: sessionToken }),
    );
  } catch (error) {
    console.warn('Error: Could not store sessionToken to sessionStorage', [
      { key: 'errorTrace', value: error },
    ]);
    successValue = false;
  }
  return successValue;
}

export const setAuth = (auth: Auth): void => {
  sessionStorage.setItem(AUTH_KEY, JSON.stringify(auth));
};

export const hasEntitlements = (): boolean => {
  return Boolean(sessionStorage.getItem(ENTITLEMENTS_KEY));
};

export const getEntitlements = (): FlightProduct[] => {
  const content = sessionStorage.getItem(ENTITLEMENTS_KEY);
  if (content) {
    return JSON.parse(content) as FlightProduct[];
  }

  throw new Error('Products not set.');
};

export const getEntitlementsByAuthor = <TProduct extends FlightProduct>(
  author: string,
): TProduct[] => {
  return (getEntitlements() ?? []).filter(
    entitlement =>
      entitlement.author.trim().toLocaleLowerCase() ===
      (author ?? '').trim().toLocaleLowerCase(),
  ) as TProduct[];
};

export const setEntitlements = (entitlements: FlightProduct[]): void => {
  sessionStorage.setItem(ENTITLEMENTS_KEY, JSON.stringify(entitlements));
};

export const clearContext = (): void => {
  sessionStorage.clear();
};

export const hasSif = (): boolean => {
  return Boolean(sessionStorage.getItem(SIF_KEY));
};

export const getSif = (): Sif => {
  const content = sessionStorage.getItem(SIF_KEY);
  if (content) {
    return JSON.parse(content) as Sif;
  }

  throw new Error('Sif token not set.');
};
