import OktaSignIn from '@okta/okta-signin-widget';

export const createAndDisplayOktaWidget = (oktaInfo, widgetRef) => {
  const oktaWidget = new OktaSignIn(oktaInfo);

  oktaWidget.showSignInAndRedirect({
    el: widgetRef.current,
  });

  return oktaWidget;
};

export default createAndDisplayOktaWidget;
