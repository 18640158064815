import { useLocation } from 'react-router-dom';
import {
  getConfigForCurrentEnv,
  getConfigTypeForPlatform,
} from '@hmhco/amp-core-env';
import { getPlatform } from '@hmhco/platform-helper';

const useOktaParams = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const { ampLogin } = getConfigForCurrentEnv(
    getConfigTypeForPlatform(getPlatform()),
  );

  const {
    client_id: clientId,
    client_secret: clientSecret,
    iss: issuer,
    state,
  } = Object.fromEntries(params.entries());

  const allKeysHaveValue =
    clientId !== undefined &&
    clientSecret !== undefined &&
    issuer !== undefined &&
    state !== undefined;

  return {
    allKeysHaveValue,
    params: {
      clientId,
      clientSecret,
      issuer,
      redirectUri: `${ampLogin}tenant-picker`,
      state,
    },
  };
};

export default useOktaParams;
