import { useEffect } from 'react';
import { initializeOktaForm } from './initializeOktaForm';

const useInitializeOktaForm = (isVisible, oktaInfo, widgetRef) => {
  useEffect(() => {
    return initializeOktaForm(isVisible, oktaInfo, widgetRef);
  }, [isVisible, oktaInfo, widgetRef]);
};

export default useInitializeOktaForm;
