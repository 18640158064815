import React from 'react';
import { Grid } from '@mui/material';
import PropTypes, { node } from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';
import MarketingSection from './MarketingSection';
import { defaultStyle, hnmStyle } from './LoginAppLayout.styles';

const styles = {
  HMH: defaultStyle,
  HNM: hnmStyle,
};

const TenantPickerLayout = ({ children, currentTheme }) => {
  const currentStyle = styles[currentTheme || 'DEFAULT'];
  const showOnDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));

  return (
    <Grid container style={currentStyle.page}>
      {showOnDesktop && (
        <Grid style={currentStyle.marketingArea} container item lg={7} md={6}>
          <MarketingSection currentTheme={currentTheme} />
        </Grid>
      )}
      <Grid
        style={currentStyle.pageContent}
        container
        item
        lg={5}
        md={6}
        sm={12}
      >
        {children}
      </Grid>
    </Grid>
  );
};

TenantPickerLayout.propTypes = {
  children: node,
  currentTheme: PropTypes.string,
};

export default TenantPickerLayout;
