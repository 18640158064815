import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'TenantPickerPageButtons' })(theme => ({
  submit: {
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 'initial',
    },
  },
}));
