import { HcpAlert } from '@hmhco/hcp-alert/src/index';
import React from 'react';

/**
 * Should be used to display generic error messages from Okta form.
 *
 */
const OktaAlert = () => {
  return (
    <div hidden="hidden" id="okta-alert" data-testid="okta-alert">
      <HcpAlert size="lg" severity="error" title="Error" subtitle="" />
    </div>
  );
};

export default OktaAlert;
