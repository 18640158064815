import merge from 'lodash/merge';
import '@ed/baseline/styles/import-cts.css';
import defaultPalette from './styles/defaultPalette';
import defaultTypography from './styles/defaultTypography';

export default (options = {}) => {
  const palette = merge({}, defaultPalette, options.palette || {});
  const typography = merge({}, defaultTypography, options.typography || {});

  return {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1600,
      },
    },
    typography,
    palette,
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            // needed for non-Typography text to have the same settings as in MUI 4
            ...typography.body2,
          },
        },
      },
      MuiAlert: {
        defaultProps: {
          size: 'lg',
          alignment: 'stacked',
        },
        styleOverrides: {
          root: ({ ownerState: { size, color, alignment }, theme }) => {
            const getAlertPadding = () => {
              if (color && color === 'neutral') {
                return size === 'lg'
                  ? `${palette.ebl.alertPaddingLg} ${palette.ebl.alertPaddingLg} ${palette.ebl.alertPaddingLg} 0px`
                  : `${palette.ebl.alertPaddingSm} ${palette.ebl.alertPaddingSm} ${palette.ebl.alertPaddingSm} 0px`;
              }
              return size === 'lg'
                ? palette.ebl.alertPaddingLg
                : palette.ebl.alertPaddingSm;
            };
            const contentPadding = {
              lg: {
                stacked: {
                  paddingTopIcon: 'var(--ebl-s1)',
                  paddingBottomIcon: '0rem',
                  paddingTopMessage: 'var(--ebl-s1)',
                  paddingBottomMessage: '0rem',
                },
                inline: {
                  paddingTopIcon: 'var(--ebl-s1)',
                  paddingBottomIcon: 'var(--ebl-s1)',
                  paddingTopMessage: 'var(--ebl-s1)',
                  paddingBottomMessage: 'var(--ebl-s1)',
                },
                none: {
                  paddingTopIcon: 'var(--ebl-s1)',
                  paddingBottomIcon: 'var(--ebl-s1)',
                  paddingTopMessage: 'var(--ebl-s1)',
                  paddingBottomMessage: 'var(--ebl-s1)',
                },
              },
              sm: {
                stacked: {
                  paddingTopIcon: 'var(--ebl-s1)',
                  paddingBottomIcon: '0',
                  paddingTopMessage: 'var(--ebl-s0)',
                  paddingBottomMessage: '0',
                },
                inline: {
                  paddingTopIcon: 'var(--ebl-s2)',
                  paddingBottomIcon: 'var(--ebl-s2)',
                  paddingTopMessage: '0.375rem',
                  paddingBottomMessage: '0.375rem',
                },
                none: {
                  paddingTopIcon: 'var(--ebl-s2)',
                  paddingBottomIcon: 'var(--ebl-s2)',
                  paddingTopMessage: '0.375rem',
                  paddingBottomMessage: '0.375rem',
                },
              },
            };

            return {
              alignItems: 'flex-start',
              padding: getAlertPadding(),
              borderWidth: '0rem',
              borderStyle: 'solid',
              '.MuiAlert-icon': {
                padding: 'unset',
                paddingTop: contentPadding[size][alignment].paddingTopIcon,
                paddingBottom:
                  contentPadding[size][alignment].paddingBottomIcon,
                // this condition applies when alignment = inline but
                // due to small screens we always show it as stacked
                // even alignment is supplied inline
                // but in case of none we keep it as none
                // so below condition takes care of alignments and breakpoint
                // if inline and small screen use stacked padding
                // if none alignment and small screen use none padding only
                [theme.breakpoints.down('md')]: {
                  paddingTop:
                    contentPadding[size][
                      alignment === 'none' ? 'none' : 'stacked'
                    ].paddingTopIcon,
                  paddingBottom: contentPadding[size].stacked.paddingBottomIcon,
                },
                svg: {
                  fill: palette.ebl.alertIconcolorInfo,
                },
                marginRight: size === 'lg' ? 'var(--ebl-s4)' : 'var(--ebl-s3)',
                fontSize: 'unset',
              },

              '.MuiAlert-message': {
                padding: 'unset',
                paddingTop: contentPadding[size][alignment].paddingTopMessage,
                paddingBottom:
                  contentPadding[size][alignment].paddingBottomMessage,
                // this condition applies when alignment = inline but
                // due to small screens we always show it as stacked
                // even alignment is supplied inline
                // but in case of none we keep it as none
                // so below condition takes care of alignments and breakpoint
                // if inline and small screen use stacked padding
                // if none alignment and small screen use none padding only
                [theme.breakpoints.down('md')]: {
                  paddingTop: contentPadding[size].stacked.paddingTopMessage,
                  paddingBottom:
                    contentPadding[size][
                      alignment === 'none' ? 'none' : 'stacked'
                    ].paddingBottomMessage,
                },
                '.MuiAlertTitle-root': {
                  marginTop: 'unset',
                  marginBottom: 'unset',
                },
              },
              '.MuiAlert-action': {
                paddingTop: '0px',
                button: {
                  marginTop: '0px',
                  marginBottom: '0px',
                },
              },
            };
          },
          standardSuccess: {
            backgroundColor: palette.ebl.alertBackgroundcolorSuccess,
            borderColor: palette.ebl.alertBordercolorSuccess,
            '.MuiAlert-icon': {
              svg: { fill: palette.ebl.alertIconcolorSuccess },
            },
            '.MuiAlert-message': {
              color: palette.ebl.alertTextcolorSuccess,
              '.MuiTypography-root': {
                color: palette.ebl.alertTextcolorSuccess,
              },
            },
          },
          standardInfo: {
            backgroundColor: palette.ebl.alertBackgroundcolorInfo,
            borderColor: palette.ebl.alertBordercolorInfo,
            '.MuiAlert-icon': {
              svg: { fill: palette.ebl.alertIconcolorInfo },
            },
            '.MuiAlert-message': {
              color: palette.ebl.alertTextcolorInfo,
              '.MuiTypography-root': {
                color: palette.ebl.alertTextcolorInfo,
              },
            },
          },
          standardWarning: {
            backgroundColor: palette.ebl.alertBackgroundcolorWarning,
            borderColor: palette.ebl.alertBordercolorWarning,
            '.MuiAlert-icon': {
              svg: { fill: palette.ebl.alertIconcolorWarning },
            },
            '.MuiAlert-message': {
              color: palette.ebl.alertTextcolorWarning,
              '.MuiTypography-root': {
                color: palette.ebl.alertTextcolorWarning,
              },
            },
          },
          standardError: {
            backgroundColor: palette.ebl.alertBackgroundcolorError,
            borderColor: palette.ebl.alertBordercolorError,
            '.MuiAlert-icon': {
              svg: { fill: palette.ebl.alertIconcolorError },
            },
            '.MuiAlert-message': {
              color: palette.ebl.alertTextcolorError,
              '.MuiTypography-root': {
                color: palette.ebl.alertTextcolorError,
              },
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            margin: 'var(--ebl-s1)',
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: 'var(--ebl-card-radius)',
          },
        },
      },
      MuiCheckbox: {
        defaultProps: {
          disableRipple: true,
          color: 'primary',
        },
        styleOverrides: {
          root: {
            margin: 'var(--ebl-s2)',
            padding: '0',
            '&.Mui-focusVisible': {
              boxShadow: 'var(--ebl-focus-ring)',
              borderRadius: '0.3125rem',
            },
            '&.MuiCheckbox-indeterminate': {
              color: palette.ebl.midGray,
            },
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            color: palette.action.disabledBackground,
            marginTop: '0px',
            marginBottom: '0px !important',
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontFamily: 'var(--ebl-wf-headings)',
            fontSize: 'var(--ebl-sh3)',
            lineHeight: 'var(--ebl-sh3-line-height)',
            letterSpacing: 'var(--ebl-sh3-letter-spacing)',
            color: palette.ebl.sh3Color,
            fontWeight: 'var(--ebl-sh3-weight)',
            textTransform: 'none',
          },
          root: {
            marginLeft: '0',
            marginRight: '0',
          },
        },
      },
      MuiFormControl: {
        defaultProps: {
          margin: 'dense',
        },
        styleOverrides: {
          marginDense: {
            marginTop: 0,
            marginBottom: 'var(--ebl-input-marginbottom)',
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
          disableTouchRipple: true,
          focusRipple: false,
        },
        styleOverrides: {
          root: {
            '&:disabled': {
              cursor: 'not-allowed',
              pointerEvents: 'auto',
            },
            '&:focus': {
              outlineWidth: '0',
            },
          },
        },
      },
      MuiButtonGroup: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: 'var(--ebl-wf-headings)',
            fontWeight: 'var(--ebl-wf-headings-weight)',
            borderRadius: 'var(--ebl-button-radius)',
            display: 'flex',
            margin: 'var(--ebl-s2)',
          },
          endIcon: {
            alignItems: 'baseline',
            lineHeight: '0',
            marginLeft: 'var(--ebl-s3)',
            marginRight: '0',
          },
          startIcon: {
            alignItems: 'baseline',
            lineHeight: '0',
            marginRight: 'var(--ebl-s2)',
            marginLeft: '0',
          },
          contained: ({ ownerState }) => ({
            boxShadow: 'none',

            '&:active': {
              boxShadow: 'none',
              backgroundColor: palette.primary.dark,
            },
            '&:disabled': {
              backgroundColor: `${palette.action.disabled} !important`, // !important to preserve color on hover
              color: palette.ebl.textWhite,
            },
            '&:focus': {
              boxShadow: 'var(--ebl-focus-ring)',
            },
            '&:hover:not(:disabled)': {
              backgroundColor: palette[ownerState.color]
                ? palette[ownerState.color].main
                : palette.primary.main,
              boxShadow: 'var(--ebl-button-hover-shadow)',
            },
          }),
          text: {
            boxShadow: 'none',
            color: palette.primary.main,
            textDecoration: 'var(--ebl-button-frameless-text-decoration)',
            backgroundColor: palette.ebl.buttonFramelessNormalBg,
            border: 'var(--ebl-button-frameless-normal-border)',

            '&:active': {
              boxShadow: 'none',
              border: `solid 1px ${palette.primary.main}`,
              backgroundColor: palette.primary.light,
              textDecoration: 'var(--ebl-button-frameless-text-decoration)',
            },
            '&:disabled': {
              backgroundColor: `${palette.action.disabled} !important`, // !important to preserve color on hover
              color: palette.ebl.textWhite,
              boxShadow: 'none !important',
            },
            '&.Mui-focusVisible': {
              boxShadow: 'var(--ebl-focus-ring)',
            },
            '&:hover': {
              backgroundColor: palette.ebl.buttonFramelessHoverBg,
              boxShadow: 'var(--ebl-button-hover-shadow)',
              border: 'var(--ebl-button-frameless-hover-border)',
              textDecoration: 'var(--ebl-button-frameless-text-decoration)',
            },
          },
          outlined: {
            backgroundColor: palette.ebl.buttonOutlinedNormalBg,
            border: `solid 1px ${palette.primary.main}`,
            color: palette.primary.main,
            '&:active': {
              backgroundColor: palette.primary.light,
            },
            '&:disabled': {
              border: 'none',
              backgroundColor: `${palette.action.disabledBackground} !important`,
              color: palette.ebl.textWhite,
              boxShadow: 'none !important',
            },
            '&:focus': {
              backgroundColor: palette.ebl.buttonOutlinedNormalBg,
              boxShadow: 'var(--ebl-focus-ring)',
            },
            '&:hover': {
              backgroundColor: palette.ebl.buttonOutlinedHoverBg,
              boxShadow: 'var(--ebl-button-hover-shadow)',
            },
          },
          sizeSmall: {
            height: 'var(--ebl-button-height-sm)',
            fontSize: 'var(--ebl-button-text-size-sm)',
            paddingLeft: 'var(--ebl-button-padding)',
            paddingRight: 'var(--ebl-button-padding)',
          },
          sizeLarge: {
            height: 'var(--ebl-button-height-lg)',
            fontSize: 'var(--ebl-button-text-size-lg)',
            paddingLeft: 'var(--ebl-button-padding)',
            paddingRight: 'var(--ebl-button-padding)',
          },
        },
      },
      MuiIconButton: {
        defaultProps: { color: 'primary', variant: 'frameless', size: 'large' },
        variants: [
          {
            props: { variant: 'contained' },
            style: ({ ownerState, theme }) => {
              const { color } = ownerState;
              const { main, dark } = theme.palette[color] || {
                // defaults for "inherit" color
                main: theme.palette.grey[600],
                dark: theme.palette.grey[800],
              };
              return {
                color: palette.ebl.textWhite,
                backgroundColor: main,

                '&:active:not(:disabled)': {
                  boxShadow: 'none',
                  backgroundColor: dark,
                },
                '&:hover': {
                  backgroundColor: main,
                  boxShadow: 'var(--ebl-button-hover-shadow)',
                },
              };
            },
          },
          {
            props: { variant: 'outlined' },
            style: ({ ownerState, theme }) => {
              const { color } = ownerState;
              const { light, background } = theme.palette[color] || {
                // defaults for "inherit" color
                light: theme.palette.grey[300],
              };
              const activeBackground = background || light; // background looks better for status colors like success/error

              return {
                border: `solid 1px`,
                backgroundColor: `${palette.ebl.buttonOutlinedNormalBg} !important`,
                padding: 'var(--ebl-s2)',

                '&:active:not(:disabled)': {
                  boxShadow: 'none',
                  backgroundColor: activeBackground,
                },
                '&:hover': {
                  backgroundColor: palette.ebl.buttonOutlinedHoverBg,
                  boxShadow: 'var(--ebl-button-hover-shadow)',
                },
              };
            },
          },
          {
            props: { variant: 'frameless' },
            style: ({ ownerState, theme }) => {
              const { color } = ownerState;
              const { light, background, main } = theme.palette[color] || {
                // defaults for "inherit" color
                light: theme.palette.grey[300],
                main: theme.palette.grey[600],
              };
              const activeBackground = background || light; // background looks better for status colors like success/error

              return {
                backgroundColor: `${palette.ebl.buttonFramelessNormalBg} !important`,
                border: 'var(--ebl-button-frameless-normal-border) !important', // !important to override Arvo main.less file
                padding: 'var(--ebl-s1)',

                '&:active:not(:disabled)': {
                  boxShadow: 'none',
                  border: `solid 1px ${main}`,
                  backgroundColor: activeBackground,
                },
                '&:hover': {
                  backgroundColor: palette.ebl.buttonFramelessHoverBg,
                  boxShadow: 'var(--ebl-button-hover-shadow)',
                  border: 'var(--ebl-button-frameless-hover-border)',
                },
                '@media (hover: none)': {
                  '&:hover': {
                    backgroundColor: `${palette.ebl.buttonFramelessHoverBg} !important`,
                  },
                },
              };
            },
          },
        ],
        styleOverrides: {
          root: {
            boxShadow: 'none',
            fontSize: 'var(--ebl-button-text-size-lg)',
            borderRadius: 'var(--ebl-button-radius)',
            margin: 'var(--ebl-s2)',
            height: 'var(--ebl-button-height-lg)',
            width: 'var(--ebl-button-height-lg)',

            '&.Mui-focusVisible': {
              boxShadow: 'var(--ebl-focus-ring)',
            },

            '&:disabled': {
              backgroundColor: `${palette.action.disabledBackground} !important`,
              color: palette.ebl.textWhite,
              boxShadow: 'none !important',
              border: 'none',
            },
          },
          sizeSmall: {
            height: 'var(--ebl-button-height-sm) !important', // !important to override Arvo main.less file
            width: 'var(--ebl-button-height-sm)',
            fontSize: 'var(--ebl-button-text-size-sm)',
            padding: 'var(--ebl-s1)',
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            margin: 'var(--ebl-s2)',
            fontFamily: 'var(--ebl-wf-headings)',
          },
          colorDefault: {
            backgroundColor: palette.ebl.black,
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: palette.ebl.tertNavBgSelected,
            },
          },
          gutters: {
            paddingTop: 0,
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: 'var(--ebl-s3)',
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            marginRight: 0,
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontFamily: 'var(--ebl-wf-headings)',
            color: palette.ebl.textBlack,
            height: 'var(--ebl-s7)',
            paddingLeft: 0,
            marginRight: 'var(--ebl-s6)',
            '&[data-align="right"]': {
              paddingRight: 'var(--ebl-s3)',
              paddingLeft: 0,
            },
          },
          head: {
            whiteSpace: 'nowrap',
            lineHeight: 'inherit',
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            minWidth: 768,
            borderRadius: 'var(--ebl-s1)',
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: palette.ebl.cardBorderColor,
            borderCollapse: 'separate',
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            height: 'var(--ebl-s7)',
            letterSpacing: 'var(--ebl-p2-letter-spacing)',
            color: palette.ebl.textGray,
            paddingLeft: 0,
            '& tr > th': {
              borderBottomColor: palette.secondary.main,
              borderBottomWidth: 'var(--ebl-table-header-border-width)',
              textTransform: 'capitalize',
              fontSize: 'var(--ebl-sh4)',
              fontWeight: 700,
              color: palette.ebl.textGray,
              '&[data-align="right"]': {
                paddingRight: 'var(--ebl-s3)',
                paddingLeft: 0,
              },
              '& .zero-dimensions': {
                width: '0',
                height: '0',
                overflow: 'hidden',
                margin: '0',
              },
            },
          },
        },
      },
      MuiTableSortLabel: {
        styleOverrides: {
          root: {
            '&.Mui-active': {
              color: palette.ebl.textBlack,
            },
          },
        },
      },
      MuiTableBody: {
        styleOverrides: {
          root: {
            borderTopWidth: 'var(--ebl-s1)',
            '& tr > td ': {
              whiteSpace: 'nowrap',
            },
            '& tr >td:first-of-type': {
              whiteSpace: 'normal',
            },
            '& tr:nth-of-type(even)': {
              backgroundColor: palette.ebl.lightGray,
            },
          },
        },
      },
      MuiTableFooter: {
        styleOverrides: {
          root: {
            '& > tr > td': {
              border: 'none',
            },
          },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          root: {
            border: 'solid',
            borderWidth: 1,
            borderColor: palette.ebl.cardBorderColor,
            height: 'auto',
          },
          actions: {
            '& button': {
              border: 'inherit',
            },
            '& svg': {
              color: palette.primary.main,
            },
          },
          selectRoot: {
            paddingTop: 4,
          },
          input: {
            borderWidth: 1,
            borderStyle: 'solid',
            borderRadius: 4,
            borderColor: palette.ebl.cardBorderColor,
          },
        },
      },
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            subtitle1: 'p',
            subtitle2: 'p',
            body1Bold: 'p',
            body2Bold: 'p',
          },
        },
        styleOverrides: {
          // styling specific to Links
          colorPrimary: {
            color: palette.ebl.linkNormalColor,
          },
        },
      },
      MuiLink: {
        defaultProps: {
          underline: 'none', // we set this to none so that we can use ed/baseline to control the text decoration in the overrides below
        },
        styleOverrides: {
          root: {
            color: `${palette.ebl.linkNormalColor} !important`,
            textDecoration: 'var(--ebl-link-normal-text-decoration) !important', // !important to override the MUI underline prop on MUILink set above to 'none'
            '&:hover': {
              // = #2d2d2d not used because of the important before
              color: palette.ebl.linkHoverColor,
              textDecoration: 'var(--ebl-link-hover-text-decoration)',
            },
            '&.Mui-focusVisible': {
              outline: 'none',
              boxShadow: 'var(--ebl-focus-ring)',
              borderRadius: '0.05rem',
            },
            '&:active': {
              // = #2d2d2d not used because of important
              color: palette.ebl.linkActiveColor,
              textDecoration: 'var(--ebl-link-active-text-decoration)',
            },
            '&:visited': {
              // = #853fba not used because of important
              color: palette.ebl.linkVisitedColor,
              textDecoration: 'var(--ebl-link-visited-text-decoration)',
            },
          },
        },
      },
      MuiSelect: {
        defaultProps: {
          variant: 'outlined',
          margin: 'dense',
          MenuProps: {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
          },
        },
        styleOverrides: {
          root: {
            fontFamily: 'var(--ebl-wf-body)',
          },
          select: {
            '&:focus': {
              backgroundColor: 'transparent',
              boxShadow: 'var(--ebl-focus-ring)',
            },
          },
          icon: {
            color: 'inherit',
            marginLeft: 'var(--ebl-dropdown-icon-margin-left)',
          },
        },
      },
      MuiInputLabel: {
        defaultProps: {
          disableAnimation: true,
          shrink: true,
        },
        styleOverrides: {
          root: {
            fontFamily: 'var(--ebl-wf-headings)',
          },
          formControl: {
            position: 'relative',
            transform: 'none !important',
            marginBottom: 'var(--ebl-input-label-margin-bottom)',
            ...typography.subtitle2,
          },
          outlined: {
            position: 'relative',
            transform: 'none !important',
            marginBottom: 'var(--ebl-input-label-margin-bottom)',
            ...typography.subtitle2,
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginTop: 'var(--ebl-s1)',
            color: palette.ebl.textBlack,
            fontFamily: 'var(--ebl-wf-headings)',
            '&.Mui-error': {
              marginLeft: 'var(--ebl-input-padding-left)',
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            minWidth: 'var(--ebl-dropdown-min-width)',
            '& [class*="MuiIconButton-root"]': {
              margin: '-2px',
              height: 'var(--ebl-button-height-sm)',
              width: 'var(--ebl-button-height-sm)',
              borderRadius: '1rem',
              '&:hover': {
                boxShadow: 'none',
              },
              '&:active:not(:disabled)': {
                backgroundColor: 'unset',
                border: 'none',
              },
            },
            '& [class*="MuiFormControl-root"]': {
              marginTop: '0px',
            },
          },
          paper: {
            boxShadow: 'var(--ebl-dropdown-panel-shadow)',
          },
          listbox: {
            '& > .MuiAutocomplete-option': {
              height: 'auto',
              display: 'block',
              paddingLeft: 'var(--ebl-s3)',
              paddingRight: 'var(--ebl-s3)',
              paddingTop: 'var(--ebl-s2)',
              paddingBottom: 'var(--ebl-s2)',
            },
          },
          option: {
            fontSize: 'var(--ebl-p2)',
            height: 'var(--ebl-dropdown-li-height)',
            '&[aria-selected="true"]': {
              backgroundColor: `${palette.ebl.dropdownLiSelectedColor} !important`,
            },
            border: '2px solid transparent',
            '&.Mui-focused': {
              '&[aria-selected="false"]': {
                backgroundColor: `${palette.ebl.white} !important`,
                borderColor: palette.ebl.focusColor,
              },
            },
            '&:hover': {
              '&[aria-selected="false"]': {
                backgroundColor: `${palette.ebl.dropdownLiHoverColor} !important`,
                borderColor: 'transparent',
              },
            },
          },
          popupIndicator: {
            color: 'inherit',
            marginLeft: 'var(--ebl-dropdown-icon-margin-left)',
          },
        },
      },
      MuiOutlinedInput: {
        defaultProps: {
          notched: false,
        },
        styleOverrides: {
          root: {
            minHeight: 'var(--ebl-input-height)',
            maxHeight: 'var(--ebl-input-max-height)',
            ...typography.body2,

            '& fieldset': {
              border: `1px solid ${palette.ebl.inputBorderColor}`,
            },
            '&.Mui-disabled fieldset': {
              cursor: 'not-allowed',
              pointerEvents: 'auto',
            },
            '&.Mui-focused fieldset': {
              boxShadow: 'var(--ebl-focus-ring)',
              borderRadius: 'var(--ebl-input-radius)',
              border: `1px solid ${palette.ebl.inputBorderColor} !important`,
            },
            '&.Mui-error fieldset': {
              border: 'var(--ebl-input-border-error) !important',
            },
            '&:hover:not($disabled):not($error) fieldset': {
              borderColor: palette.ebl.inputBorderHoverColor,
            },
          },
          inputSizeSmall: {
            // prevent double padding in multiline inputs
            '&.:not(textarea)': {
              paddingLeft: 'var(--ebl-input-padding-left)',
              paddingRight: 'var(--ebl-input-padding-right)',
            },
          },
        },
      },
      MuiMenu: {
        defaultProps: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        },
        styleOverrides: {
          paper: {
            '&.MuiPaper-root': {
              border: `1px solid ${palette.ebl.dropdownBorderColor}`,
              borderRadius: 'var(--ebl-dropdown-border-radius)',
              boxShadow: 'var(--ebl-dropdown-panel-shadow)',
              maxHeight: 'var(--ebl-dropdown-panel-max-height)',
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontFamily: 'var(--ebl-wf-headings)',
            height: 'var(--ebl-dropdown-li-height)',
            fontSize: 'var(--ebl-sh3)',
            padding: '0px var(--ebl-s3) !important',
            '&:hover': {
              backgroundColor: palette.ebl.dropdownLiHoverColor,
            },
            '&.Mui-selected': {
              backgroundColor: palette.ebl.dropdownLiSelectedColor,
              '&:hover': {
                backgroundColor: palette.ebl.dropdownLiHoverColor,
              },
              '&.Mui-focusVisible': {
                backgroundColor: 'rgba(0, 0, 0, 0.12)', // set to match other options
              },
            },
            '& [class*="MuiIconButton-root"]': {
              // Checkbox Icon unique to multi-select dropdown
              width: 0,
              height: 0,
            },
          },
        },
      },
      MuiLinearProgress: {
        styleOverrides: {
          root: {
            borderRadius: 'var(--ebl-linear-progress-bar-radius)',
            height: 'var(--ebl-linear-progress-bar-height)',
            flexGrow: 1,
          },
          colorPrimary: {
            backgroundColor: palette.ebl.linearProgressRangeColor,
          },
          bar: {
            borderRadius: 'var(--ebl-linear-progress-bar-radius)',
          },
          barColorPrimary: {
            backgroundColor: palette.ebl.linearProgressValueColor,
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            position: 'absolute',
            flexGrow: 1,
            width: '100%',
            borderBottom: 'var(--ebl-tert-nav-border-bottom)',
          },
          indicator: {
            borderBottom: 'var(--ebl-tert-nav-indicator-border)',
            borderBottomColor: palette.primary.main,
          },
          scrollButtons: {
            '&:hover': {
              backgroundColor: palette.ebl.tertNavScrollHover,
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            ...typography.subtitle1,
            border: 'var(--ebl-tert-nav-indicator-border)',
            borderLeft: 'none',
            borderRight: 'none',
            borderColor: 'transparent',
            height: 'var(--ebl-tert-nav-height)',
            padding: '0 var(--ebl-tert-nav-tabs-padding)',
            '&:hover': {
              borderBottomColor: palette.primary.light,
            },
            '&:focus': {
              border: `0.0625rem solid ${palette.ebl.focusColor}`,
            },
            '&.Mui-selected': {
              color: typography.subtitle1.color,
            },
          },
          textColorInherit: {
            fontSize: 'var(--ebl-sh1)',
            opacity: 1,
          },
        },
      },

      MuiDialog: {
        styleOverrides: {
          paper: {
            margin: 0,
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: '0',
            borderTop: '1px var(--ebl-border-hairline-gray) solid',
            paddingTop: 'var(--ebl-modal-button-group-margin-top)',
            marginLeft: 'var(--ebl-modal-padding)',
            marginRight: 'var(--ebl-modal-padding)',
            justifyContent: 'space-between',
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            padding:
              'var(--ebl-modal-padding) var(--ebl-modal-padding) var(--ebl-modal-title-margin-bottom) var(--ebl-modal-padding)',
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: '0 var(--ebl-modal-padding)',
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            ...typography.subtitle2,
            '&.Mui-focused:not(.Mui-error)': {
              color: 'inherit',
            },
          },
        },
      },
      MuiFormGroup: {
        styleOverrides: {
          root: {
            fontFamily: 'var(--ebl-s4)',
            color: palette.ebl.black,
            fontWeight: 'var(--ebl-sh2-weight)',
            fontSize: 'var(--ebl-sh2)',
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            padding: 'var(--ebl-s2)',

            '&:hover': {
              boxShadow: 'none',
              backgroundColor: 'transparent',
            },
            '&.Mui-focusVisible': {
              '& span': {
                boxShadow: 'var(--ebl-focus-ring)',
                borderRadius: '0.1rem', // square
              },
            },
            '&.MuiIconButton-root': {
              margin: '0',
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          rounded: {
            borderRadius: 'var(--ebl-s2)',
          },
          outlined: {
            border: `1px solid ${palette.ebl.cardBorderColor}`,
          },
        },
      },
      MuiInputBase: {
        defaultProps: {
          size: 'small',
        },
        styleOverrides: {
          inputSizeSmall: {
            paddingLeft: 'var(--ebl-input-padding-left)',
            paddingRight: 'var(--ebl-input-padding-right)',
          },
          input: {
            '&::placeholder': {
              color: palette.ebl.textGray,
              opacity: 1,
            },
          },
        },
      },
      MuiSlider: {
        styleOverrides: {
          root: {
            color: palette.primary.main,
            minWidth: 'var(--ebl-slider-minwidth)',
            marginTop: 'var(--ebl-slider-margintop)',
            width: '100%',
            alignSelf: 'center',
            marginLeft: 'var(--ebl-slider-padding)',
            marginReft: 'var(--ebl-slider-padding)',
          },
          rail: {
            color: palette.ebl.midGray,
            height: 'var(--ebl-slider-range-height)',
            opacity: 1,
            borderRadius: 'var(--ebl-slider-range-borderradius)',
            paddingLeft: 'var(--ebl-slider-padding)',
            paddingRight: 'var(--ebl-slider-padding)',
            width: '100%',
          },
          track: {
            height: 'var(--ebl-slider-range-height)',
            border: 'none',
          },
          thumb: {
            height: 25,
            width: 25,
            backgroundColor: palette.ebl.textWhite,
            border: 'var(--ebl-slider-thumb-border)',
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          margin: 'dense',
          variant: 'outlined',
        },
      },
      MuiToolbar: {
        styleOverrides: {
          regular: {
            height: 'var(--ebl-nav-height)',
            padding:
              '0 var(--ebl-nav-padding-left) 0 var(--ebl-nav-padding-right)',

            '@media (min-width: 600px)': {
              padding:
                '0 var(--ebl-nav-padding-left) 0 var(--ebl-nav-padding-right)',
            },
          },
        },
      },
      MuiToggleButton: {
        defaultProps: {
          disableRipple: true,
          disableTouchRipple: true,
          focusRipple: false,
        },
      },
      MuiWithWidth: {
        defaultProps: { initialWidth: 'lg' },
      },
    },
  };
};
