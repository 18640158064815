import {
  updateLabels,
  updateForgotPassword,
  updateFields,
  updateBackButton,
} from './utils';

export const updateWidget = widgetRef => {
  updateLabels(widgetRef);
  updateForgotPassword(widgetRef);
  updateFields(widgetRef);
  updateBackButton(widgetRef);
};

export default updateWidget;
