import { TenantPickerTypes } from './types';

export const oktaVisible = visible => ({
  type: TenantPickerTypes.OKTA_VISIBLE,
  oktaVisible: visible,
});

export const setCachedOktaWidgetConfig = oktaWidgetConfig => ({
  type: TenantPickerTypes.SET_CACHED_OKTA_WIDGET_CONFIG,
  oktaWidgetConfig,
});

export default {};
