import React from 'react';
import { useSelector } from 'react-redux';
import Username from './Username';
import Password from './Password';
import useGetUserHasNavigatedFromDistrictSelect from '../../hooks/getUserHasNavigatedFromDistrictSelect.hook';
import { getShowUsernameAndPassword } from '../../store/selectors/UsernameAndPassword';
import { INITIALIZE_URI_MOCKING } from '../../constants/constants';

const HCPUsernameAndPasswordForm = () => {
  const shouldUsernameFocus = useGetUserHasNavigatedFromDistrictSelect();
  const renderUsernameAndPassword = useSelector(getShowUsernameAndPassword);
  const { oktaEmbeddedWidget } = INITIALIZE_URI_MOCKING; // This will be the response from the initializeURI endpoint

  if (!renderUsernameAndPassword || oktaEmbeddedWidget) {
    return null;
  }
  return (
    <>
      <Username shouldUsernameFocus={shouldUsernameFocus} />
      <Password />
    </>
  );
};
export default HCPUsernameAndPasswordForm;
