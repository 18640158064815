import keyMirror from '@hmhco/react-utils/src/keyMirror';

export const CountryStateOrgTypes = keyMirror({
  SET_COUNTRY: null,
  SET_STATE: null,
  SET_ORG: null,
  SET_CACHED_ORG: null,
});

export const UsernameAndPasswordTypes = keyMirror({
  LOGIN_FAILED: null,
  SET_USERNAME: null,
  SET_PASSWORD: null,
  SET_SHOW_USERNAME_AND_PASSWORD: false,
});

export const ForgotPasswordOrgTypes = keyMirror({
  FORGOT_PASSWORD_UPDATE_ORG_NAME: null,
  FORGOT_PASSWORD_SET_DISABLED_FLAG: null,
  FORGOT_PASSWORD_SET_ORG_LOADING: null,
  FORGOT_PASSWORD_SET_ORG_ERROR: null,
});

export const ForgotPasswordStateTypes = keyMirror({
  FORGOT_PASSWORD_UPDATE_USERNAME: null,
  FORGOT_PASSWORD_REQUEST_IS_LOADING: null,
  FORGOT_PASSWORD_REQUEST_HAS_ERROR: null,
  FORGOT_PASSWORD_REQUEST_IS_SUCCESS: null,
});

export const RequestSigninStateTypes = keyMirror({
  SET_EMAIL: null,
  REQUEST_SIGNIN_SET_ISVALID: null,
  REQUEST_SIGNIN_REQUEST_IS_LOADING: null,
  REQUEST_SIGNIN_REQUEST_HAS_ERROR: null,
  REQUEST_SIGNIN_REQUEST_IS_SUCCESS: null,
  REQUEST_SIGNIN_SET_DISABLED_FLAG: null,
});

export const OktaStateTypes = keyMirror({
  SHOW_OKTA: null,
});

export const TenantPickerTypes = keyMirror({
  OKTA_VISIBLE: null,
  SET_CACHED_OKTA_WIDGET_CONFIG: null,
});
